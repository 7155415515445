<template>
  <div class="contactcomponent">
    <!-- Title -->
    <div class="headercontact">
      <div class="">
        <h1 class="">
          {{ $t("contact.title") }}
        </h1>
        <p class="">
          {{ $t("contact.p") }}
        </p>
      </div>
    </div>
    <!-- Mapa -->
    <div class="contact2">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.2662349914976!2d-70.02727388514438!3d-15.842598489021245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915d69934266e579%3A0xdbde5cba27081ffb!2sMosoq%20Inn%20Hotel!5e0!3m2!1ses!2spe!4v1606540677116!5m2!1ses!2spe"
        class="mapa"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
    <!-- Info -->
    <div class="contact2">
      <h2 class="">{{ $t("contact.arrive") }}</h2>
      <div>
        <div class="htgitems">
          <i class="bx bxs-plane" style="color: #533e2d; margin-right: 5px"></i>
          <p>{{ $t("contact.aero") }}</p>
        </div>
        <div class="htgitems">
          <i class="bx bxs-bus" style="color: #533e2d; margin-right: 5px"></i>
          <p>{{ $t("contact.terminal") }}</p>
        </div>
        <div class="htgitems">
          <i class="bx bxs-train" style="color: #533e2d; margin-right: 5px"></i>
          <p>{{ $t("contact.train") }}</p>
        </div>
        <div class="htgitems">
          <i class="bx bxs-ship" style="color: #533e2d; margin-right: 5px"></i>
          <p>{{ $t("contact.puerto") }}</p>
        </div>
      </div>
    </div>
    <!-- Contact -->
    <div class="contact1">
      <h2 class="">{{ $t("contact.contact") }}</h2>
      <p class="contactitem1" id="contactitem">{{ $t("contact.tel") }}</p>
      <p class="">(051) 367 518</p>
      <p class="contactitem1" id="contactitem">{{ $t("contact.cel") }}</p>
      <p class="">
        <a id="cel" href="https://wa.link/7b7y0r" target="_blank"
          >+51 951 623 199</a
        >
      </p>
      <p class="contactitem1" id="contactitem">{{ $t("contact.mail") }}</p>
      <p class="">reservas@mosoqinnperu.com</p>
      <p class="contactitem1" id="contactitem">{{ $t("contact.address") }}</p>
      <p class="">Jr. Moquegua 673, Puno, Puno, Perú</p>
    </div>
    <!-- <div class="contact4">
      <div
        class="fb-page"
        data-href="https://www.facebook.com/mosoqinn"
        data-tabs="timeline" 
        data-width="800" 
        data-height="300"
        data-small-header="true"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/mosoqinn"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/mosoqinn">Mosoq Inn</a>
        </blockquote>
      </div>
    </div> -->
    <!-- FACEBOOK PAGE -->
    <!-- <div class="contact3">
      <h2>{{ $t("contact.fb") }}</h2>
      <div
        class="fb-page"
        data-href="https://www.facebook.com/mosoqinn"
        data-tabs="timeline, messages"
        data-width="400"
        data-height="300"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/mosoqinn"
          class="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/mosoqinn">Mosoq Inn</a>
        </blockquote>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style>
#contactitem {
  font-family: "Aladin", cursive;
}
#cel {
  color: #242331;
  text-decoration: none;
}
.contactcomponent {
  margin-top: 50px;
}
.headercontact {
  text-align: center;
  display: flex;
  flex: 1;
  padding: 0 30px;
}
.contact1 {
  background-color: #fffbe8;
  padding: 1rem 0;
  text-align: center;
}
.contact2 {
  padding: 1rem 50px;
}
.contact3 {
  padding: 1rem 0;
  text-align: center;
}
.contact4{
  padding: 1rem 50px;
display: flex;
flex:1;
}
.fb-page{
  flex: 1;
}
.contactitem1 {
  margin-bottom: 0;
}
.htgitems {
  display: flex;
  flex-direction: row;
}
.mapa {
  width: 100%;
  height: 250px;
}
/*  */
@media (min-width: 992px) {
}
@media (min-width: 800px) and (max-width: 991px) {
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
}
</style>