<template>
  <div class="contact">
    <ContactC/>
  </div>
</template>

<script>
import ContactC from '../components/Contact/ContactC.vue'
export default {
  name: "Contact",
  components: {
    ContactC
  }
}
</script>

<style>


</style>